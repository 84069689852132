<template>
  <div class="flow">
    <div class="standard clearfix">
      <div class="fl left">
        <div class="hearder tc">{{ $t("membershipFee") }}</div>
        <div class="main">
          <div
            class="item clearfix"
            v-for="(item, index) in parseList"
            :key="index"
          >
            <div class="fl">{{ item.text }}</div>
            <div class="fr mainColor">{{ item.parse }}</div>
          </div>
        </div>
      </div>
      <div class="fr right">
        <div class="title">{{ $t("memberGuide") }}</div>
        <div class="course">
          <div v-for="(it, index) in flowList" :key="index" class="item">
            <div class="flowItem">{{ it.title }}</div>
            <div>{{ it.explain }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="equity">
      <div class="title">{{ $t("vipCompanyEquity") }}</div>
      <div class="clearfix">
        <div class="content left fl">
          <div>1、{{ $t("vipItem") }}</div>
          <div>2、{{ $t("vipItem1") }}</div>
          <div>3、{{ $t("vipItem2") }}</div>
          <div>4、{{ $t("vipItem3") }}</div>
        </div>
        <div class="fr">
          <el-image
            :src="LOCALE == 'en' ? flowImg1 : flowImg"
            class="img"
            :alt="$t('siffa')"
          ></el-image>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parseList: [
        {
          text: this.$t("unitMember"),
          parse: this.$t("yearParce", { year: "2000" }),
        },
        {
          text: this.$t("unitDirector"),
          parse: this.$t("yearParce", { year: "5500" }),
        },
        {
          text: this.$t("vicePresidentUnit"),
          parse: this.$t("yearParce", { year: "8000" }),
        },
        {
          text: this.$t("presidentUnit"),
          parse: this.$t("yearParce", { year: "10000" }),
        },
      ],
      flowList: [
        {
          title: "1、" + this.$t("saveapplication"),
          explain: this.$t("intendedUnits"),
        },
        {
          title: "2、" + this.$t("internalReview"),
          explain: this.$t("approvalMembership"),
        },
        // {
        //   title: "3、会领导批准",
        //   explain: "经会长办公会研究，做出同意入会的决定",
        // },
        {
          title: "3、" + this.$t("issueMembership"),
          explain: this.$t("issueItem"),
        },
      ],
      flowImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship1.png",
      flowImg1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/shipEn.png",
    };
  },
};
</script>
<style lang="less" scoped>
.flow {
  .standard {
    height: 346px;
    .left {
      width: 318px;
      border: 1px solid #2152d4;
      border-radius: 11px;
      .hearder {
        background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/ship.png")
          no-repeat;
        background-size: 100%;
        height: 117px;
        color: #ffffff;
        font-size: 21px;
        padding-top: 25px;
      }
      .main {
        border-top: none;
        height: 228px;
        padding: 10px 8px 44px 8px;
        .item {
          margin: 24px 0;
          font-size: 16px;
        }
      }
    }
    .right {
      width: 460px;
      .title {
        font-size: 21px;
        padding-top: 28px;
        font-weight: 500;
      }
      .course {
        .item {
          margin: 22px 0;
          .flowItem {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  .equity {
    height: 250px;
    margin-top: 30px;
    .left {
      width: 318px;
    }
    .title {
      font-size: 21px;
    }
    .content {
      margin-top: 30px;
      padding-bottom: 30px;
      div {
        margin-top: 20px;
        font-size: 16px;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
    .img {
      margin-top: 20px;
    }
  }
}
</style>